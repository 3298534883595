
import {
  isObject,
  mergeFormDataWithDefault,
  resolveDependentFormDataForSubmission,
  resolveEmptyFormDataForSubmission,
  resolveFormDataForValidation,
  testOnChangeConditionals,
  testOnSubmitConditionals,
} from '@/utils/forms/form-resolver.js'
import { validateFormData } from '@/utils/forms/form-validate.js'
import { isEmpty, isEqual } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import FormLayout from '@/components/common/FormLayout.vue'
import { resolveSubmissionData } from '@/utils/forms/form-submission-data.js'
import {
  CONDITIONAL_ACTION_TYPE_DIALOG,
  CONDITIONAL_ACTION_TYPE_FLOW,
  CONDITIONAL_ACTION_TYPE_SUBMIT,
} from '@/utils/forms/form-constants'
import SchemaBasedFormDialog from '@/components/forms/SchemaBasedFormDialog.vue'
import { ContextualizedFormResolver } from '@/utils/forms/contextualized-form-resolver.js'
import { FormContext } from '@/utils/forms/form-context.js'
import FormSubmission from '@/models/form_submission'
import { defineComponent } from 'vue'

interface Page {
  page: number
  flow: string | null
  data: any
}

export default defineComponent({
  name: 'SchemaBasedForm',
  components: {
    SchemaBasedFormDialog,
    FormLayout,
  },
  beforeRouteLeave(to, _from, next) {
    let isGoingToLeave = true

    if (this.confirmRouteLeave) {
      const confirmation = window.confirm(
        'There are unsaved changes, are you sure you want to discard them?'
      )

      if (!confirmation) {
        isGoingToLeave = false
      }
    }

    if (!isGoingToLeave) {
      next(false)
    } else {
      this.isSnackbarVisible = false
      next()
    }
  },
  props: {
    formSubmissionId: {
      type: String,
      default: '',
    },
    formId: {
      type: String,
      required: true,
    },
    businessUnitId: {
      type: String,
      required: true,
    },
    parentRouteName: {
      type: String,
      default: '',
    },
    additionalContext: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      errorSchema: {},
      isValidating: false,
      confirmRouteLeave: false,
      pageStack: [
        {
          page: 0,
          flow: null,
          data: {},
        },
      ] as Page[],
      persistantPageStack: [] as Page[],
      snackbar: {
        type: 'info',
        timeout: 6000,
        messageKey: null,
        showActions: true,
        messages: {
          formSubmissionError: 'Failed to submit the form',
          formSubmissionUpdateError: 'Failed to update the form',
          formSubmissionValidationError:
            'Please correct the errors on the form',
        },
      },
      isSnackbarVisible: false,
      dialog: {
        title: '',
        body: '',
        buttonLabel: 'Close',
        primaryAction: {},
      },
      isDialogVisible: false,
      initialDataLoaded: false,
    }
  },
  computed: {
    ...mapGetters({
      getFormById: 'forms/getById',
      getFormByIdAndSchemaId: 'forms/getByIdAndSchemaId',
      getFormSubmissionById: 'formSubmissions/getById',
      getFormSubmissionSaving: 'formSubmissions/getSaving',
      getFormFieldMemory: 'formSubmissions/getFormFieldMemory',
      getBusinessUnitById: 'businessUnits/getBusinessUnitById',
      getUserBySid: 'users/getBySid',
    }),
    contextualizedFormResolver() {
      const hub = this.getBusinessUnitById(this.businessUnitId)
      const currentUser =
        this.getUserBySid(this.$auth.user.sub) || this.$auth.user

      const context = new FormContext({
        hub,
        currentUser,
        additionalContext: this.additionalContext,
      })
      return new ContextualizedFormResolver(context)
    },
    formSubmission(): FormSubmission | null {
      if (this.formSubmissionId) {
        return this.getFormSubmissionById(this.formSubmissionId)
      }

      return null
    },
    formSubmissionPageStack() {
      if (this.formSubmission && this.form) {
        const data = this.formSubmission.submission
        return resolveSubmissionData(this.form.structure, data)
      }

      return {}
    },
    form() {
      // If we have a submission, we need the exact schema version
      if (this.formSubmission?.schemaId) {
        const form = this.getFormByIdAndSchemaId(this.formId, this.formSubmission.schemaId)
        if (form) return form
      }
      // Otherwise get the most recent version
      return this.getFormById(this.formId) || {}
    },
    submitButtonLabel(): string {
      const onSubmitConditionalActions = testOnSubmitConditionals(
        this.rootSchema,
        this.currentData
      )
      if (onSubmitConditionalActions.length > 0) {
        for (const action of onSubmitConditionalActions) {
          switch (action.type) {
            case CONDITIONAL_ACTION_TYPE_FLOW:
              return 'Next'
            case CONDITIONAL_ACTION_TYPE_SUBMIT:
              return 'Submit'
          }
        }
      }

      if (this.rootSchema.nextButtonMessage) {
        return this.rootSchema.nextButtonMessage
      }

      return this.isLastPage ? 'Submit' : 'Next'
    },
    discardButtonLabel(): string {
      if (this.rootSchema.backButtonMessage) {
        return this.rootSchema.backButtonMessage
      }

      return this.isFirstPage ? 'Cancel' : 'Back'
    },
    rootSchema() {
      if (this.currentFlow !== null) {
        return this.form.structure.flows[this.currentFlow].pages[
          this.currentPage
        ]
      }
      return this.form.structure.pages[this.currentPage]
    },
    schema() {
      return this.rootSchema.schema
    },
    uiSchema() {
      return this.rootSchema.uiSchema
    },
    isFirstPage(): boolean {
      return this.pageStack.length === 1
    },
    isLastPage(): boolean {
      if (this.currentFlow !== null) {
        return (
          this.currentPage ===
          this.form.structure.flows[this.currentFlow].pages.length - 1
        )
      }
      return this.currentPage === this.form.structure.pages.length - 1
    },
    isSaving(): boolean {
      return this.isValidating || this.getFormSubmissionSaving
    },
    currentPage() {
      return this.pageStack[this.pageStack.length - 1].page
    },
    currentFlow() {
      return this.pageStack[this.pageStack.length - 1].flow
    },
    currentData: {
      get: function () {
        return this.pageStack[this.pageStack.length - 1].data
      },
      set: function (data) {
        this.pageStack[this.pageStack.length - 1].data = data
      },
    },
    formData() {
      return this.pageStack.reduce(function (o, cur) {
        return { ...o, ...cur.data }
      }, {})
    },
    hideSubmitButton(): boolean {
      return this.rootSchema.hideNextButton ?? false
    },
    formLoaded(): boolean {
      return !!this.form?.structure
    },
  },
  async mounted() {
    if (this.formSubmissionId) {
      await this.fetchFormSubmission(this.formSubmissionId)

      if (this.formSubmission?.schemaId &&
        !this.getFormByIdAndSchemaId(this.formId, this.formSubmission.schemaId)) {
        await this.fetchFormByIdAndSchemaId({
          id: this.formId,
          schemaId: this.formSubmission.schemaId
        })
      }

      if ('pages' in this.formSubmissionPageStack) {
        this.pageStack[0].data = this.formSubmissionPageStack.pages[0]
      }
    } else {
      this.pageStack[0].data = this.getDefaultPageData(
        this.currentPage,
        this.currentFlow,
        this.currentData
      )
    }
    this.initialDataLoaded = true
  },
  beforeUpdate() {
    const newFormData = this.getDefaultPageData(
      this.currentPage,
      this.currentFlow,
      this.currentData
    )
    if (!isEqual(newFormData, this.currentData)) {
      this.onChange(newFormData)
    }
  },
  methods: {
    ...mapActions({
      fetchFormSubmission: 'formSubmissions/fetchFormSubmission',
      fetchFormByIdAndSchemaId: 'forms/fetchByIdAndSchemaId',
      createFormSubmission: 'formSubmissions/createFormSubmission',
      updateFormSubmission: 'formSubmissions/updateFormSubmission',
    }),
    validate(): boolean {
      const resolvedSchema = this.contextualizedFormResolver.retrieveSchema(
        this.schema,
        this.schema,
        this.currentData
      )
      const resolvedData = resolveFormDataForValidation(this.currentData)

      const errorSchema = validateFormData(resolvedSchema, resolvedData)
      this.errorSchema = errorSchema

      return isEmpty(errorSchema)
    },
    getDefaultPageData(pageNumber: number, flowName: string | null, formData) {
      let pageSchema = {}

      if (flowName !== null) {
        pageSchema =
          this.form.structure.flows[flowName].pages[pageNumber].schema
      } else {
        pageSchema = this.form.structure.pages[pageNumber].schema
      }

      let defaultData = this.contextualizedFormResolver.getDefaultFormData(
        pageSchema,
        pageSchema,
        formData,
        this.getFormFieldMemory(this.form?.id)
      )

      if (isObject(formData)) {
        defaultData = mergeFormDataWithDefault(formData, defaultData)
      }
      return defaultData
    },
    nextPage() {
      const nextPage = this.currentPage + 1
      let data = {}

      const visitedIndex = this.persistantPageStack.findIndex(
        (page) => page.page === nextPage && page.flow === this.currentFlow
      )
      if (visitedIndex !== -1) {
        data = this.persistantPageStack[visitedIndex].data
      } else if (
        this.currentFlow === null &&
        'pages' in this.formSubmissionPageStack
      ) {
        data = this.formSubmissionPageStack.pages[nextPage]
      } else if (
        this.currentFlow !== null &&
        'flows' in this.formSubmissionPageStack
      ) {
        data =
          this.formSubmissionPageStack.flows[this.currentFlow].pages[nextPage]
      } else {
        data = this.getDefaultPageData(nextPage, this.currentFlow, data)
      }

      // Reset scroll position
      const formLayoutContent = this.$el.querySelector('.content')
      if (formLayoutContent) formLayoutContent.scrollTop = 0

      this.pageStack.push({
        page: nextPage,
        flow: this.currentFlow,
        data,
      } as Page)
    },
    goToFlow(flowName: string, flowPage = 0) {
      let data = {}

      const visitedIndex = this.persistantPageStack.findIndex(
        (page) => page.page === flowPage && page.flow === flowName
      )
      if (visitedIndex !== -1) {
        data = this.persistantPageStack[visitedIndex].data
      } else if ('flows' in this.formSubmissionPageStack) {
        data = this.formSubmissionPageStack.flows[flowName][flowPage]
      } else {
        data = this.getDefaultPageData(flowPage, flowName, data)
      }

      // Reset scroll position
      const formLayoutContent = this.$el.querySelector('.content')
      if (formLayoutContent) formLayoutContent.scrollTop = 0

      this.pageStack.push({
        page: flowPage,
        flow: flowName,
        data,
      } as Page)
    },
    async onSubmit() {
      if (this.isSaving) return

      this.isValidating = true
      const isValid = this.validate()
      if (isValid) {
        this.prepareCurrentDataForSubmission()

        const onSubmitConditionalActions = testOnSubmitConditionals(
          this.rootSchema,
          this.currentData
        )
        if (onSubmitConditionalActions.length > 0) {
          this.handleConditionalActions(onSubmitConditionalActions)
        } else {
          if (this.isLastPage) {
            await this.submit()
          } else {
            this.nextPage()
          }
        }
      } else {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'formSubmissionValidationError',
        })
      }
      this.isValidating = false
    },
    async submit() {
      this.confirmRouteLeave = false

      if (this.formSubmission && this.form.isEditable()) {
        await this.updateFormSubmission({
          formSubmissionId: this.formSubmissionId,
          formData: this.formData,
          version: this.formSubmission.version,
          form: this.form,
        })
          .then(() => {
            this.$emit('submitted', this.formSubmissionId)
            this.dismiss()
          })
          .catch(() => {
            this.setSnackbarData({
              type: 'error',
              messageKey: 'formSubmissionUpdateError',
            })
          })
      } else if (!this.formSubmission) {
        await this.createFormSubmission({
          form: this.form,
          ownerId: this.businessUnitId,
          formData: this.formData,
        })
          .then((formSubmissionId) => {
            this.$emit('submitted', formSubmissionId)
            this.dismiss()
          })
          .catch(() => {
            this.setSnackbarData({
              type: 'error',
              messageKey: 'formSubmissionError',
            })
          })
      } else {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'formSubmissionUpdateError',
        })
      }
    },
    prepareCurrentDataForSubmission() {
      const resolveEmpty = resolveEmptyFormDataForSubmission(this.currentData)
      this.currentData = resolveDependentFormDataForSubmission(
        this.schema,
        resolveEmpty
      )
    },
    onChange(formData) {
      if (this.isSaving) return
      this.confirmRouteLeave = true

      const originalFormData = { ...this.currentData }
      if (isObject(formData)) {
        this.currentData = this.getDefaultPageData(
          this.currentPage,
          this.currentFlow,
          formData
        )

        const onChangeConditionalActions = testOnChangeConditionals(
          this.rootSchema,
          this.currentData,
          originalFormData
        )
        if (onChangeConditionalActions.length > 0) {
          this.handleConditionalActions(onChangeConditionalActions)
        }
      }
    },
    previousPage() {
      if (this.isFirstPage) {
        this.dismiss()
      } else {
        const poppedPage = this.pageStack.pop()
        const visitedIndex = this.persistantPageStack.findIndex(
          (page) => page.page === this.currentPage + 1
        )
        if (!poppedPage) {
          return
        }
        if (visitedIndex === -1) {
          this.persistantPageStack.push(poppedPage)
        } else {
          this.persistantPageStack[visitedIndex] = poppedPage
        }
      }
    },
    dismiss() {
      if (this.parentRouteName) {
        this.$router.push({ name: this.parentRouteName })
      }
      this.$emit('dismiss')
    },
    setSnackbarData({
      type = 'info',
      messageKey = null,
    }: {
      type: 'info' | 'error'
      messageKey: string | null
    }) {
      Object.assign(this.snackbar, { type, messageKey })
      this.isSnackbarVisible = true
    },
    handleConditionalActions(actions) {
      for (const action of actions) {
        switch (action.type) {
          case CONDITIONAL_ACTION_TYPE_DIALOG:
            this.setDialogData(action.args)
            return
          case CONDITIONAL_ACTION_TYPE_FLOW:
            this.goToFlow(action.args.name, action.args.page)
            return
          case CONDITIONAL_ACTION_TYPE_SUBMIT:
            this.submit()
            return
        }
      }
    },
    setDialogData(actionArgs) {
      const body = actionArgs.body
      const title = actionArgs.title
      const primaryAction = actionArgs.primaryAction?.onClick ?? {}
      const buttonLabel = actionArgs.primaryAction?.content ?? 'Close'
      Object.assign(this.dialog, { body, title, primaryAction, buttonLabel })
      this.isDialogVisible = true
    },
    onCloseDialog() {
      if (!isEmpty(this.dialog.primaryAction)) {
        this.handleConditionalActions(this.dialog.primaryAction)
      }
      this.isDialogVisible = false
    },
  },
})
