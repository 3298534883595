import {get} from './base'

const formEndPointV3 = '/api/v3/forms'

export type GetFormsCategoryResponse = {
  id: string
  name: string
  hidden: boolean
  editable: boolean
}

export type GetFormsAccessGrantResponse = {
  business_unit_id: string
  role_restrictions: string[]
}

export type GetFormsResponse = {
  form_id: number
  name: string
  id: string
  category: GetFormsCategoryResponse | null
  state: string
  fillable: boolean
  owner_id: string
  access_grants: GetFormsAccessGrantResponse[]
  schema_id: number
  version: Date
  structure: Record<string, any>
}

export function getForms() {
  return get(`${formEndPointV3}`, {})
}

export function getFormByIdAndSchemaId(id: string, schemaId: number) {
  return get(`${formEndPointV3}/${id}/schema/${schemaId}`, {})
}
