import FormAccessGrant from '@/models/form-access-grant'
import FormCategory from '@/models/form-category'
import {GetFormsAccessGrantResponse, GetFormsResponse} from '@/api/forms'

export enum FormState {
  Active = 'Active',
  Archived = 'Archived',
}

export default class Form {
  schemaId: number
  formId: number
  name: string
  id: string
  structure: Record<string, any>
  category: FormCategory | null
  state: FormState
  fillable: boolean
  ownerId: string
  accessGrants: FormAccessGrant[]
  version: Date

  constructor(json: GetFormsResponse) {
    this.formId = json.form_id
    this.name = json.name
    this.id = json.id
    this.schemaId = json.schema_id
    this.structure = json.structure
    this.category = json.category ? new FormCategory(json.category) : null
    this.state = json.state as FormState
    this.fillable = json.fillable
    this.ownerId = json.owner_id
    this.accessGrants = json.access_grants
      ? json.access_grants.map(
          (ag: GetFormsAccessGrantResponse) => new FormAccessGrant(ag)
        )
      : []
    this.version = json.version
  }

  isJobForm = () => {
    return this.category?.hidden ?? false
  }

  isEditable = () => {
    return (this.category?.editable ?? false) || this.isJobForm()
  }
}
