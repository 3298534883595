import { getFormByIdAndSchemaId, getForms, GetFormsResponse } from '@/api/forms'
import Form, { FormState } from '@/models/form'

interface FormsState {
  all: Record<string, Form>
  loading: boolean
}

const state = (): FormsState => {
  return {
    all: {},
    loading: false,
  }
}

const CREATE_PREVIEW_FORM = 'CREATE_PREVIEW_FORM'
const SET_PREVIEW_STRUCTURE = 'SET_PREVIEW_STRUCTURE'

const cacheKey = (id: string, schemaId: number) => `${id}_${schemaId}`

const getters = {
  getAll: (state: FormsState) => {
    const formsByBaseId = Object.values(state.all).reduce((acc, form) => {
      const baseId = form.id
      if (!acc[baseId] || form.version > acc[baseId].version) {
        acc[baseId] = form
      }
      return acc
    }, {} as Record<string, Form>)

    return Object.values(formsByBaseId)
  },

  getAllVisible: (state: FormsState) =>
    getters.getAll(state).filter(
      (form) =>
        (!form.category?.hidden ?? true) &&
        !(form.state !== FormState.Active)
    ),

  getHasAnyVisibleForms: (_, getters) => getters.getAllVisible.length > 0,

  getById: (state: FormsState) => (id: string) => {
    let mostRecent: Form | null = null

    Object.values(state.all).forEach(form => {
      if (form.id === id && (!mostRecent || form.version > mostRecent.version)) {
        mostRecent = form
      }
    })

    return mostRecent
  },

  getByIdAndSchemaId: (state: FormsState) => (id: string, schemaId: number) =>
    state.all[cacheKey(id, schemaId)] || null,

  getLoading: (state: FormsState) => state.loading,
}

const actions = {
  async fetch({ commit }) {
    commit('LOADING_FORMS')

    let formsData = await getForms()
    formsData = formsData.data

    commit('RECEIVE_FORMS', formsData)
    commit('LOADED_FORMS')
  },

  async fetchByIdAndSchemaId({commit}, {id, schemaId}) {
    commit('LOADING_FORMS')
    let formData = await getFormByIdAndSchemaId(id, schemaId)
    formData = formData.data

    commit('RECEIVE_FORM', formData)
    commit('LOADED_FORMS')
  },

  createPreviewForm({ commit }) {
    commit(CREATE_PREVIEW_FORM)
  },

  updatePreviewFormStructure({ commit }, struct: GetFormsResponse) {
    commit(SET_PREVIEW_STRUCTURE, { struct })
  },
}

const mutations = {
  RECEIVE_FORMS(state: FormsState, data: GetFormsResponse[]) {
    const newForms = {...state.all}

    data.forEach((json) => {
      const form = new Form(json)
      const key = cacheKey(form.id, form.schemaId)
      newForms[key] = form
    })

    state.all = Object.freeze(newForms)
  },

  RECEIVE_FORM(state: FormsState, data: GetFormsResponse) {
    const form = new Form(data)
    const key = cacheKey(form.id, form.schemaId)

    state.all = Object.freeze({
      ...state.all,
      [key]: form
    })
  },

  LOADING_FORMS(state: FormsState) {
    state.loading = true
  },

  LOADED_FORMS(state: FormsState) {
    state.loading = false
  },

  [CREATE_PREVIEW_FORM](state: FormsState) {
    const builderForm = new Form({} as GetFormsResponse)
    builderForm.id = 'preview_form'
    builderForm.schemaId = 0
    builderForm.structure = {
      pages: [{}],
    }
    builderForm.version = new Date()

    const key = cacheKey(builderForm.id, builderForm.schemaId)

    state.all = Object.freeze({
      ...state.all,
      [key]: builderForm
    })
  },

  [SET_PREVIEW_STRUCTURE](state: FormsState, { struct }) {
    const key = cacheKey('preview_form', 0)
    if (state.all[key]) {
      state.all[key].structure = struct
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
