import { PriceBookItemQueryResponse } from '@/api/types/priceBookTypes'

export default class PriceBookItem {
  id: string
  name: string
  type: string
  defaultRate: number | null
  description: string | null
  unitOfMeasure: string | null
  associatedHubId: string | null
  associatedResourceTypeIds: number[]
  constructor(json: PriceBookItemQueryResponse) {
    this.id = json.id
    this.name = json.name
    this.type = json.type
    this.defaultRate = json.default_rate
    this.description = json.description
    this.unitOfMeasure = json.unit_of_measure
    this.associatedHubId = json.associated_hub_id
    this.associatedResourceTypeIds = json.associated_resource_type_ids
  }
}
