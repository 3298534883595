import FollowUpAction from '@/models/follow-up-action'
import FormSubmissionComment from '@/models/form-submission-comment'
import FormSubmissionLike from '@/models/form-submission-like'

export default class FormSubmission {
  schemaId: number
  formSubmissionId: string
  formId: string
  number: number | null
  createdBy: string
  created: string
  comments: FormSubmissionComment[]
  likes: FormSubmissionLike[]
  tags: string[]
  submission: any
  followUpActions: FollowUpAction[]
  assignedTo: { id: string; type: string } | null
  subjectId: string | null
  subjectLocation: { id: number; parentId: number; name: string } | null
  submitterBusinessUnitId: string
  version: string
  ownerId: string
  jobNumber: string | null
  
  constructor(json) {
    this.formSubmissionId = json.id
    this.formId = json.form_id
    this.number = json.number
    this.createdBy = json.created_by
    this.created = json.created
    this.comments =
      json.comments?.map((c) => new FormSubmissionComment(c)) ?? []
    this.likes = json.likes?.map((c) => new FormSubmissionLike(c)) ?? []
    this.tags = json.tags
    this.submission = json.submission
    this.followUpActions =
      json.followup_actions?.map((fa) => new FollowUpAction(fa)) ?? []
    this.assignedTo =
      json.assigned_to != null
        ? {
            id: json.assigned_to.assignee_id,
            type: json.assigned_to.assignee_type,
          }
        : null
    this.subjectId = json.subject_id
    this.subjectLocation =
      json.subject_location != null
        ? {
            id: json.subject_location.id,
            parentId: json.subject_location.parent_id,
            name: json.subject_location.name,
          }
        : null
    this.submitterBusinessUnitId = json.submitter_business_unit_id
    this.version = json.version
    this.ownerId = json.owner_id
    this.jobNumber = json.job_number
    this.schemaId = json.schema_id
  }

  static ASSIGNMENT_ENTITY_TYPE_COMPANY = 'Company'
  static ASSIGNMENT_ENTITY_TYPE_USER = 'User'
  static ASSIGNMENT_ENTITY_TYPE_BUSINESS_UNIT = 'BusinessUnit'
}

export const FollowupActionStatus = Object.freeze({
  None: 'None',
  RequiredAndUnassigned: 'RequiredAndUnassigned',
  RequiredAndAssigned: 'RequiredAndAssigned',
  Completed: 'Completed',
})
